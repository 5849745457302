














import { Component } from "vue-property-decorator";
import { CreateTipologiaFornituraModule } from '../store';
import { TipologiaFornituraModel } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

@Component({})
export default class PgaTechAreaIndex extends mixins(SharedMixin) {
    isLoading = true;

    headers: Array<any> = [
        {
            text: 'Code',
            align: 'start',
            sortable: true,
            value: 'Code',
            width: '10%',
            keySearch: "co",
        },
        {
            text: 'Title',
            align: 'start',
            sortable: true,
            value: 'Name',
            keySearch: "ti",
        },
        {
            text: 'Status',
            allign: 'start',
            value: 'Status',
            width: '10%',
        }
    ];

    get tipologiaFornitura(): Array<TipologiaFornituraModel> {
        return CreateTipologiaFornituraModule.TipologiaFornitura;
    }

    async mounted() : Promise<void> {
        await CreateTipologiaFornituraModule.getAllTipologiaFornitura();
        this.isLoading = false;
    }

    rowClicked(item: any) : void {
        const id = item.Id;
        this.$router.push({ name: 'tipologiafornituradetails', params: { id } });
    }
}
