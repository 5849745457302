











































import { Component} from "vue-property-decorator";
import { CreateTipologiaFornituraModule } from './store';
import { SharedModule } from '@/feature/Shared/store';

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";

import PgaTipologiaFornituraDetails from './Components/PgaTipologiaFornituraDetails.vue';
import { EntityOrderStatus, TipologiaFornituraModel } from '@/services/iOrderService';
import { SendEmailToPermissionsCommand } from "@/services/iNotificationService";
import { EmailsStore } from "@/feature/Shared/Email/store";

@Component({
	components: {
		PgaTipologiaFornituraDetails
	}
})
export default class TipologiaFornituraPageDetails extends mixins(SharedMixin) {
	showDialog = false;
	private title = "";
	private message = "";

	get tipologiaFornituraDetails() : TipologiaFornituraModel {
		return CreateTipologiaFornituraModule.TipologiaFornituraDetails;
	}

	ShowDialogDelete(): void {
		this.title = "Deleting Supply Typology";
		this.message = `Are you sure you want to delete the supply tipology '${this.tipologiaFornituraDetails.Name}'?`;
		this.showDialog = true;
	}

	ShowDialogRequestDelete(): void {
		this.title = "Request Deletion Supply Typology";
		this.message = `Are you sure you want to request deletion of supply tipology '${this.tipologiaFornituraDetails.Name}'?`;
		this.showDialog = true;
	}

	HideDialog() : void
	{
		this.showDialog = false;
	}

	async mounted() : Promise<void> {
		const TipologiaFornituraId: string = this.$route.params.id;
		CreateTipologiaFornituraModule.getTipologiaFornituraById(TipologiaFornituraId);
	}

	async deleteTipologiaFornitura() : Promise<void> {
		await CreateTipologiaFornituraModule.DeleteTipologiaFornituraAPI(this.tipologiaFornituraDetails.Id);

		this.showDialog = false;
		this.$router.go(-1);
	}

	async approve(): Promise<void> {
		try {
			await CreateTipologiaFornituraModule.ApproveSupplyTipology();
		} catch (reason) {
			SharedModule.SET_ALERT_ERROR_MESSAGE(`error approving og supply Typology: ${reason}`);
			SharedModule.SET_ALERT_IS_VISIBLE(true);
		}
	}

	async approveDeletion(): Promise<void> {
		try {
			await CreateTipologiaFornituraModule.ApproveDeletionSupplyTipology();
			this.$router.go(-1);
		} catch (reason) {
			SharedModule.SET_ALERT_ERROR_MESSAGE(`error approving deletion of supply Typology: ${reason}`);
			SharedModule.SET_ALERT_IS_VISIBLE(true);
		}
	}

	private edit() : void
	{
		const id = this.tipologiaFornituraDetails.Id;
		this.$router.push({ name: 'supplytipologyedit', params: { id } });
	}

	private requestChanges()
	{
		const sendEmailCommand : SendEmailToPermissionsCommand = 
		{
			PermissionIds: ["351F5003-3B93-A30D-6D77-1AFF43975C70"],
			Subject: `Request changes for SupplyTipology of ${this.tipologiaFornituraDetails.Name}`,
			Body: "",
		};
		EmailsStore.SET_SEND_EMAIL_TO_PERMISSIONS_COMMAND(sendEmailCommand);
		EmailsStore.SET_SHOW_FORM_EMAIL_TO_PERMISSIONS(true);
	}
	
	get isApprovable(): boolean {
		return this.tipologiaFornituraDetails.Status == EntityOrderStatus.Created;
	}

	get isApprovableDeletion(): boolean {
		return this.tipologiaFornituraDetails.Status == EntityOrderStatus.DeletionRequest;
	}

	get isDeletable(): boolean {
    return this.tipologiaFornituraDetails.Status == EntityOrderStatus.Created ||
            this.tipologiaFornituraDetails.Status == EntityOrderStatus.Approved;
	}

	get isRequestableDeletion(): boolean {
		return this.tipologiaFornituraDetails.Status !== EntityOrderStatus.DeletionRequest &&
			this.tipologiaFornituraDetails.Status !== EntityOrderStatus.Created;
	}

	get isEditable() : boolean
	{
		return this.tipologiaFornituraDetails.Status === EntityOrderStatus.Created;
	}
}
