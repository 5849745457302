


















































import { Component, Model } from "vue-property-decorator";
import { TipologiaFornituraModel } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

@Component({})
export default class TipologiaFornituraDetails extends mixins(SharedMixin) {
  @Model("", { type: Object })
  readonly Model!: TipologiaFornituraModel;

  hasTipologiaFornitura = false;
  isLoading = true;

  async mounted(): Promise<void> {
    this.hasTipologiaFornitura = this.Model !== undefined;

    if (this.hasTipologiaFornitura) {
      this.isLoading = false;
    }
  }
}
