













import { Component } from "vue-property-decorator"

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";

import PgaTipologiaFornituraIndex from './Components/PgaTipologiaFornituraIndex.vue';

@Component({
    components: {
        PgaTipologiaFornituraIndex
    }
})
export default class TipologiaFornituraIndex extends mixins(SharedMixin) { }
