













    import { Component } from "vue-property-decorator";
    import { CreateTipologiaFornituraModule } from './store';
    import { mixins } from 'vue-class-component';
    import SharedMixin from "@/mixins/SharedMixin";

    import PgaTipologiaFornituraCreate from './Components/PgaTipologiaFornituraCreate.vue';
    @Component({
        components: {
            PgaTipologiaFornituraCreate
        }
    })
    export default class TipologiaForniturasPageCreate extends mixins(SharedMixin) {

        get tipologiaFornituraCreate() {
            return CreateTipologiaFornituraModule.TipologiaFornituraCreate;
        }

        async CreateTipologiaFornitura() {
            try {
                const response = await CreateTipologiaFornituraModule.createTipologiaFornitura();
                const id: string = response.Id.toString();
                this.$router.replace({ name: 'tipologiafornituradetails', params: { id } });
            }
            catch (error) {
                console.log(error);
            }
        }

        undo() {
            this.$router.go(-1);
        }

        beforeDestroy() : void
	{
		CreateTipologiaFornituraModule.RESET_STATE_CREATE();
	}
    }
