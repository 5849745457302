































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateTipologiaFornituraModule } from "../store";
import { OfferServiceModule } from "../../store";
import { CreateOfferModule } from "../../ValutazioneOfferta/store";
import {
  TipologiaFornituraCommand,
  TipologiaFornituraModel,
} from "../../../../services/iOrderService";
import PgaTextField from "@/components/inputs/PgaTextField.vue";
import PgaSelect from "@/components/inputs/PgaSelect.vue";

@Component({
  data: () => ({}),

  components: {
    PgaTextField,
    PgaSelect,
  },
})
export default class PgaTipologiaFornituraCreate extends Vue {
  @Model("", { type: Object })
  readonly Model!: TipologiaFornituraModel;

  get Code() : string {
    return this.Model.Code;
  }
  set Code(code: string) {
    CreateTipologiaFornituraModule.SET_TIPOLOGIAFORNITURA_CODE_CREATE(code);
  }

  get Name() : string {
    return this.Model.Name;
  }
  set Name(name: string) {
    CreateTipologiaFornituraModule.SET_TIPOLOGIAFORNITURA_NAME_CREATE(name);
  }

  get User(): Array<any> {
    return this.Model.Users;
  }
  set User(user: Array<any>) {
    CreateTipologiaFornituraModule.SET_TIPOLOGIAFORNITURA_USER_CREATE(user);
  }

  get Errors(): Map<string, Array<string>> {
    return CreateTipologiaFornituraModule.TipologiaFornituraCreate.Errors;
  }
}
